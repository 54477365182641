import React from "react";
import {Category} from "../../../api/ppb";
import FormHeader from "../../Form/FormHeader";
import CategoryButtonItem from "./CategoryButtonItem";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../Navigation/routeNames";

const ViewDocumentsCategoryButtonList = (props: ViewDocumentsCategoryButtonListProps) => {
    const history = useHistory();

    const onCategoryClicked = (category: Category) => {
        if (props.isMyDocumentsRoute) {
            history.push(`${routeNames.myDocumentsByCategory.path}/${category.id}`);
            return;
        }
        history.push(`${routeNames.documentByCategory.path}/${category.id}`);
    };

    return (
        <React.Fragment>
            {props.categoryList.length > 0 ? (
                <React.Fragment>
                    <FormHeader headerName={"Select a Category"} />
                    <div className="row ml-0 mr-0 mt-3 button-list">
                        {props.categoryList.map((category: Category, index) => {
                            return (
                                <CategoryButtonItem
                                    category={category}
                                    onCategoryClicked={onCategoryClicked}
                                    key={index}
                                />
                            );
                        })}
                    </div>
                </React.Fragment>
            ) : (
                <div className="row ml-0 mr-0 mt-3 button-list">
                    <div className="col text-center">
                        <h6>
                            There are no categories. Navigate to the categories page to create one
                        </h6>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default ViewDocumentsCategoryButtonList;

export interface ViewDocumentsCategoryButtonListProps {
    categoryList: Category[];
    isMyDocumentsRoute: boolean;
}
