import React, {useEffect, useState} from "react";
import {DocumentViewRecordWithDocument} from "../../../api/ppb";
import FormHeader from "../../Form/FormHeader";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import FormRow from "../../Form/FormRow";
import FilterDocumentDropDown, {
    FilterType,
    getFilterTypeFromString
} from "../../Dropdown/FilterDocumentDropdown";
import {toArray} from "../../../utils/sortingUtils";
import MyDocumentListItem from "./MyDocumentListItem";
import FormActionContainer from "../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../Button/MCButton";
import {routeNames} from "../../Navigation/routeNames";
import {nullifyUserDocStore} from "../../../store/userDocuments/actions/UserDocumentsActions";

const MyDocumentList = (props: DocumentViewRecordWithDocument[]) => {
    const params: any = useParams();
    const categoryList = useSelector((state: RootStore) => state.categories.data || []);
    const [filter, setFilter] = useState<FilterType>(FilterType.All);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(nullifyUserDocStore());
        };
    }, []);

    const getCategoryName = () => {
        const categoryId = +params.id;

        const category = categoryList.find((item) => item.id === categoryId);
        if (!category) return "Undefined Category Name";
        return category.name;
    };

    const backToCategoriesSelection = () => {
        history.push(routeNames.myDocuments.path);
    };

    return (
        <React.Fragment>
            <div className="mt-3 mb-3">
                <FormHeader headerName={`${getCategoryName()} Documents`} />
                <FormRow rowName={"Filter Documents"} columnDetailClassName={"pr-0 pl-0"}>
                    <FilterDocumentDropDown
                        id={filter}
                        changeOption={(id) => setFilter(getFilterTypeFromString(id.toString()))}
                        disabled={false}
                    />
                </FormRow>
            </div>
            {filterDocuments(filter, toArray(props), +params.id).length > 0 ? (
                <React.Fragment>
                    <div className="row ml-0 mr-0 mt-3 button-list">
                        {filterDocuments(filter, toArray(props), +params.id).map((item, index) => {
                            return (
                                <MyDocumentListItem
                                    documentViewRecord={item}
                                    disabled={false}
                                    key={index}
                                />
                            );
                        })}
                    </div>
                </React.Fragment>
            ) : (
                <div className="row ml-0 mr-0 fadeIn">
                    <div className="col">
                        <h5 className="text-center p-3">
                            {filter === FilterType.All ? (
                                <span>There are no documents at this time!</span>
                            ) : (
                                <span>There are no documents matching this filter!</span>
                            )}{" "}
                        </h5>
                    </div>
                </div>
            )}
            <div className="row-modifier">
                <FormActionContainer>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Back"}
                        onClick={backToCategoriesSelection}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                </FormActionContainer>
            </div>
        </React.Fragment>
    );
};

export default MyDocumentList;

export function filterUpdatedDocuments(
    documents: DocumentViewRecordWithDocument[]
): DocumentViewRecordWithDocument[] {
    return documents.filter((doc) => doc.date && doc.document.date > doc.date);
}

export function filterReadDocuments(
    documents: DocumentViewRecordWithDocument[]
): DocumentViewRecordWithDocument[] {
    return documents.filter((doc) => doc.date && doc.date > doc.document.date);
}

export function filterUnreadDocuments(
    documents: DocumentViewRecordWithDocument[]
): DocumentViewRecordWithDocument[] {
    return documents.filter((doc: DocumentViewRecordWithDocument) => doc.date === undefined);
}

export function filterDocuments(
    filter: FilterType,
    documentList: DocumentViewRecordWithDocument[],
    categoryId?: number
): DocumentViewRecordWithDocument[] {
    const docs = categoryId
        ? documentList.filter((item) => item.document.categoryId === categoryId)
        : documentList;
    switch (filter) {
        case FilterType.All:
            return docs;
        case FilterType.Read:
            return filterReadDocuments(docs);
        case FilterType.Updated:
            return filterUpdatedDocuments(docs);
        case FilterType.Unread:
            return filterUnreadDocuments(docs);
    }
}
