import React, {useEffect} from "react";
import {Category} from "../../../../api/ppb";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    nullifyCategoryStore,
    saveCategory,
    setCategory
} from "../../../../store/category/actions/CategoryActions";
import FormHeader from "../../../Form/FormHeader";
import {DebounceInput} from "react-debounce-input";
import FormRow from "../../../Form/FormRow";
import ClinicalGradesDropdown from "../../../Dropdown/ClinicalGradesDropdown";
import FormActionContainer from "../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {Modal, useModal} from "pulse-modal";
import {routeNames} from "../../../Navigation/routeNames";
import {showErrorToast, showSuccessToast} from "../../../../utils/toastUtils";

const EditCategoryForm = (props: Category) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {toggle, isShown} = useModal();

    useEffect(() => {
        return () => {
            dispatch(nullifyCategoryStore());
        };
    }, []);

    const backToCategoriesList = () => {
        history.push(routeNames.categoryList.path);
    };

    const save = async () => {
        const valid = isFormValid(props);
        if (!valid) return;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(saveCategory(props));
        if (!success) return;

        showSuccessToast("Saved Category");
        backToCategoriesList();
    };

    return (
        <React.Fragment>
            <div className="mt-3">
                <FormHeader headerName={"Category Form"} />
                <FormRow rowName={"Category Name"} columnDetailClassName={"pr-0 pl-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields"}
                        inputType={"input"}
                        onChange={(event) =>
                            dispatch(
                                setCategory({
                                    ...props,
                                    name: event.target.value
                                })
                            )
                        }
                        disabled={false}
                        value={props.name}
                        placeholder="Enter category name..."
                    />
                </FormRow>
                <FormRow rowName={"Clinical Grades"} columnDetailClassName={"pr-0 pl-0"}>
                    <ClinicalGradesDropdown
                        changeOptions={(items) => {
                            dispatch(
                                setCategory({
                                    ...props,
                                    clinicalGrades: items
                                })
                            );
                        }}
                        ids={props.clinicalGrades}
                    />
                </FormRow>
                <div className="row-modifier">
                    <FormActionContainer withColumn>
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Save"}
                            onClick={save}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Cancel"}
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </FormActionContainer>
                </div>
            </div>
            <Modal
                modalSize={"sm"}
                title={"Unsaved Changes"}
                bodyChildren={
                    <div className="row ml-0 mr-0">
                        <p className="mb-0">
                            There are unsaved changes on this page, are you sure you want to
                            navigate away?
                        </p>
                    </div>
                }
                onClose={toggle}
                isShown={isShown}
                footerChildren={
                    <React.Fragment>
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Yes"}
                            onClick={backToCategoriesList}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"No"}
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </React.Fragment>
                }
            />
            {/*<div className="form-heading pb-2">Category Name</div>*/}
            {/*<input*/}
            {/*    className="mb-3"*/}
            {/*    type="text"*/}
            {/*    name="Category Name"*/}
            {/*    value={category.name}*/}
            {/*    onChange={categoryNameChanged}*/}
            {/*/>*/}
            {/*{Object.keys(categoryNameErr).map((key: any) => {*/}
            {/*    return (*/}
            {/*        <p className="error-text" key={nanoid()}>*/}
            {/*            {categoryNameErr[key]}*/}
            {/*        </p>*/}
            {/*    );*/}
            {/*})}*/}
            {/*<div className="form-heading mt-2 pb-2">Clinical Grades</div>*/}
            {/*<div className="form-heading mt-2 pb-2">*/}
            {/*    <ClinicalGradeDropdown*/}
            {/*        ids={clinicalGradeOptions}*/}
            {/*        changeOptions={onChangedClinicalGrades}*/}
            {/*    />*/}
            {/*</div>*/}
            {/*<button className="btn-base-lg save-btn-lg" onClick={save} disabled={!isFormValid}>*/}
            {/*    Save*/}
            {/*</button>*/}
            {/*<button className="btn-base-lg cancel-btn-lg" onClick={cancel}>*/}
            {/*    Cancel*/}
            {/*</button>*/}
            {/*<Modal*/}
            {/*    isShown={isShown}*/}
            {/*    hide={toggle}*/}
            {/*    headerText={modalProps.headerText}*/}
            {/*    modalSize={ModalSize.small}*/}
            {/*    modalContent={*/}
            {/*        <ConfirmationModal*/}
            {/*            onConfirm={modalProps.onConfirm}*/}
            {/*            onCancel={() => toggle()}*/}
            {/*            message={modalProps.message}*/}
            {/*        />*/}
            {/*    }*/}
            {/*/>*/}
        </React.Fragment>
    );
};

export default EditCategoryForm;

function isFormValid(category: Category): boolean {
    let valid = true;

    if (category.name.length < 3) {
        showErrorToast("Category name must be more than 2 characters");
        valid = false;
    }

    return valid;
}
